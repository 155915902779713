<template>
   <div class="elb">
     <el-form :model="formData" label-width="80px" ref="formRef">
       <!-- 身份证号输入框 -->
       <el-form-item label="身份证号" prop="idCard" :rules="[{ required: true, pattern: /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/, message: '请输入身份证号', trigger: 'blur' }]">
           <el-input v-model="formData.idCard" placeholder="请验证身份证号"></el-input>
         </el-form-item>
         <el-form-item>
         <el-button type="primary" @click="onSubmit">去验证</el-button>
       </el-form-item>
       </el-form>
       </div>
</template>
<script>
 import axios from 'axios';
 import { baseURL } from '@/api/base.js';
 
 export default {
   data(){
       return {
       formData: {
         idCard:''
       }
     }
   },
   methods:{
       onSubmit(){
        var that=this;
           axios.post(baseURL + '/api/zk/verify', {idcard:this.formData.idCard
           }, {
             headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
             }
           })
           .then((response) => {
             if(response.data.code==200){
              localStorage.setItem('idcard',response.data.idcard)
              if(that.$route.params.id==1){
                that.$router.push('/UpdateTel')
              }else if(this.$route.params.id==3){
                that.$router.push('/VerifyTel')
              }else{
                console.log(that.$route.params.id)
              }
             }else{
               this.$message.error(response.data.msg);
             }

           })
           .catch((error) => {
             console.error('提交错误:', error);
           }); 
       }
   }
 }
 </script>
 <style>
 .elb{
   margin-top: 20px;
 }
</style>