<template>
    <div class="register-wrapper">
    <el-card style="border-radius:20px;">
    <img src="../images/background.png" class="register-data2"/>
    </el-card>
      <el-card class="register-card">
        <h1>招考系统注册</h1>
        <el-form @submit.prevent="register" :model="registerForm" ref="registerForm" label-width="120px">
          
          <!-- 用户名输入框 -->
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请正确输入用户名，必须是数字和字母', trigger: 'blur',pattern:/[A-Z|a-z|0-9]+$/},{ validator: validateUsername, trigger: 'blur'}]">
            <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
  
          <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' },{min:6,message:'密码不小于6位',trigger:'blur'}]">
              <el-input type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
<!-- 密码输入框 -->
          <el-form-item label="确认密码" prop="repassword" :rules="[{required:true,validator:validatePassword, trigger: 'blur' }]">
              <el-input type="password" v-model="repassword" placeholder="请输入确认密码"></el-input>
          </el-form-item>

          <!-- 手机号输入框 -->
          <el-form-item label="手机号" prop="phone" :rules="[{ required: true, pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }]">
            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
  
          <!-- 获取验证码按钮，当手机号正确时才显示 -->
          <el-form-item v-if="isPhoneValid">
            <el-button type="primary" @click="getvCode" :disabled="isButtonDisabled">
              {{ buttonLabel }}
            </el-button>
          </el-form-item>
  
          <!-- 验证码输入框 -->
          <el-form-item label="验证码" v-if="showvCode" prop="vCode" :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]">
            <el-input v-model="registerForm.vCode" placeholder="请输入验证码"></el-input>
          </el-form-item>
  
          <!-- 身份证号输入框 -->
          <el-form-item label="身份证号" prop="idCard" :rules="[{ required: true, pattern: /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/, message: '请输入身份证号', trigger: 'blur' }]">
            <el-input v-model="registerForm.idCard" placeholder="请输入正确的身份证号"></el-input>
          </el-form-item>
  
          <!-- 注册按钮 -->
          <el-form-item>
            <el-button type="primary" @click="register">立即注册</el-button>
          </el-form-item>
        </el-form>
        <router-link to="/login">已有账号？立即登录</router-link>
      </el-card>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ElMessage } from 'element-plus';
  import {baseURL } from '@/api/base.js';
  export default {
    data() {
      return {
        registerForm: {
          username: '',
          email: '',
          password: '',
          phone: '',
          vCode: '',
          idCard: '',
        },
        repassword:'',
        showvCode: false,
        isButtonDisabled: false,
        buttonLabel: '获取验证码',
        countdown: 60,
        apiurl:baseURL
      };
    },
    created(){
       if(localStorage.getItem('token')){
         this.$router.push('/')
       }
       var _this = this;
    document.onkeydown = function() {   //按下回车提交
        let key = window.event.keyCode;  
        //事件中keycode=13为回车事件
        if (key == 13) {
            _this.register();
        }
    };
    },
    computed: {
      isPhoneValid() {
        const phoneRegex = /^[1][3-9][0-9]{9}$/;
        return phoneRegex.test(this.registerForm.phone);
      }
    },
    methods: {
     // 自定义验证函数，用来验证密码和确认密码是否一致
     validatePassword(rule, value, callback) {
    if (this.repassword === '') {
      callback(new Error('请输入确认密码'));
    } else if (this.repassword!== this.registerForm.password) {
      callback(new Error('密码不一致'));
    }else{
      callback();
    }
  },
  validateUsername(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入用户名'));
    }
    // 模拟调用接口检查用户名是否已注册
    this.checkUsernameAvailability(value).then(isAvailable => {
      if (!isAvailable) {
        callback(new Error('该用户名已注册'));
      } else {
        callback();
      }
    }).catch(() => {
      callback(new Error('验证用户名时出错'));
    });
  },
  checkUsernameAvailability(username) {
    var that=this;
    return new Promise((resolve) => {
          axios.post(that.apiurl+'/api/register/validateuser',{username:username}).then((res)=>{
               console.log(res)
               if(res.data.code!=200){
                  resolve(false)
               }else{
                   resolve(true)
               }
          })
    })
  },
      getvCode() {
        var that=this;
        if (this.isPhoneValid) {
          axios.post(that.apiurl+'/api/sendcode', { phone: this.registerForm.phone })
            .then((res) => {
             if(res.data.data.success){
                ElMessage.success('验证码发送成功！');
              this.showvCode = true;
              this.startCountdown();
             }else{
                ElMessage.error('验证码发送失败');
             }
            })
            .catch(() => {
              ElMessage.error('验证码发送失败');
            });
        } else {
          ElMessage.error('请输入有效的手机验证码');
        }
      },
      startCountdown() {
        this.isButtonDisabled = true;
        this.buttonLabel = `请等待${this.countdown} 秒`;
  
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown > 0) {
            this.buttonLabel = `请等待 ${this.countdown} 秒`;
          } else {
            clearInterval(timer);
            this.buttonLabel = '重新获取';
            this.isButtonDisabled = false;
            this.countdown = 60;
          }
        }, 1000);
      },
      async register() {
        var that=this;
        this.$refs.registerForm.validate(async (valid) => {
          if (valid&&this.registerForm.vCode) {
            try {
              const response = await axios.post(that.apiurl+'/api/register', this.registerForm);
              if(response.data.token){
                ElMessage.success('注册成功');
                localStorage.setItem('token', response.data.token);
                this.$router.push('/');
              }else{
                ElMessage.error(response.data.msg);
              }
            } catch (error) {
              ElMessage.error(error);
            }
          } else {
            ElMessage.error('请正确输入表单');
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .register-wrapper {
    background-color: #ADD8E6;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .register-card {
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 500px;
  }
  .register-data2{
    width: 500px;
    height:445px;
    background: rgba(255, 255, 255, 0.8); /* 添加透明度，避免背景图影响表单可读性 */
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
  </style>