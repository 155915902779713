// src/api.js
import axios from 'axios';

export const baseURL = 'https://exam.ycrc88.com';

const apiClient = axios.create({
  baseURL, // 这里使用 baseURL
  timeout: 10000,
});

export default apiClient;
