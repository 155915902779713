<template>
    <div class="elb">
      <el-form-item style="padding-left:2%;" label="新手机号：" prop="tel" :rules="phoneRules">
        <el-input v-model="formData.tel" placeholder="请输入新手机号"></el-input>
        <el-button @click="getvCode" :disabled="isButtonDisabled">
          {{buttonLabel}}
        </el-button>
      </el-form-item>
      <el-form-item label="验证码" v-if="showvCode" prop="logincode">
        <el-input v-model="formData.vcode" placeholder="请输入验证码"></el-input>
      </el-form-item>
        </div>
</template>
<script>
  import axios from 'axios';
  import { baseURL } from '@/api/base.js';
  import { ElMessage} from 'element-plus';
  export default {
    beforeMount(){
      axios.post(baseURL + '/api/zk/verifyidcard', {idcard:localStorage.getItem('idcard')}, {
             headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
             }
           })
           .then((response) => {
             if(response.data.code!=200){
               this.$router.push('/UpdatePassword')
             }else{
               this.formData.tel=response.data.tel
             }
           })
           .catch((error) => {
             console.error('提交错误:', error);
          });
    },
    data(){
        return {
        formData: {
          tel: '',
          vcode: '',
        },
        showvCode: false,
        isButtonDisabled: false,
        countdown:60,
        // 完善手机号验证规则
        phoneRules: [
          {
            pattern: /^[1][3-9][0-9]{9}$/,
            message: '请输入正确的11位手机号',
            trigger: 'blur'
          }
        ],
        buttonLabel:'获取验证码'
      }
    },
    methods:{
        onSubmit(){
            axios.post(baseURL + '/api/zk/verifytel', {vcode:this.formData.vcode,
              tel:this.formData.tel
            }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then((response) => {
              if(response.data.code==200){
                this.$router.push('/')
              }else{
                this.$message.error(response.data.msg);
              }

            })
            .catch((error) => {
              this.$message.error('密码修改失败');
              console.error('提交错误:', error);
            }); 
        },
        startCountdown() {
        this.isButtonDisabled = true;
        this.buttonLabel = `请等待${this.countdown} 秒`;
  
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown > 0) {
            this.buttonLabel = `请等待 ${this.countdown} 秒`;
          } else {
            clearInterval(timer);
            this.buttonLabel = '重新获取';
            this.isButtonDisabled = false;
            this.countdown = 60;
          }
        }, 1000);
      },
      getvCode() {
        if (this.isPhoneValid) {
          axios.post(baseURL + '/api/sendcode/verify', {tel:this.formData.tel}, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then((res) => {
              if (res.data.data.success) {
                ElMessage.success('验证码发送成功！');
                this.showvCode = true;
                this.startCountdown();
              } else {
                ElMessage.error('验证码发送失败'+res.data.msg);
              }
            })
          .catch(() => {
              ElMessage.error('手机号已注册或验证失败');
            });
        } else {
          // 显示更详细的手机号验证失败提示
          ElMessage.error('请输入正确的11位手机号');
        }
      }
    },
    mounted(){
        
    }
  }
  </script>
  <style>
  .elb{
    margin-top: 20px;
  }
</style>