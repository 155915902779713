<template>
  <!-- <el-breadcrumb class="custom-breadcrumb">
      <template v-slot:default>
        <span class="custom-span">欢迎来到招考系统>当前页面：会员中心-</span>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
     <el-breadcrumb-item :to="{ path: '/UpdatePassword' }">修改信息</el-breadcrumb-item>
    </template>
</el-breadcrumb> -->
    <div class="green">
      <span class="title">招考系统</span>
      <span class="username">
      {{username}}
    </span>
      <span class="exit" @click="exit">退出</span>
    </div>
    <div>
    <el-container style="height: 100vh;" scroll="no">
      <div class="br">
        <el-breadcrumb class="custom-breadcrumb">
    <template v-slot:default>
      <span class="member">欢迎来到招考系统>当前页面：会员中心-</span>
      <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index" :to="item.to">{{ item.text }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</div>
      <el-aside width="200px" class="el-aside">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <i class="el-icon-house"></i>
            <span>中心首页</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-edit-outline"></i>
            <span>我要报名</span>
          </el-menu-item>
            <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span>报名记录</span>
          </el-menu-item>
          <el-menu-item index="8">
            <i class="el-icon-bell"></i>
            <span>修改信息</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-message"></i>
            <span>我的消息</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i class="el-icon-wallet"></i>
            <span>缴费记录</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="el-icon-bell"></i>
            <span>系统公告</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
  
      <!-- 右侧内容区域，使用 router-view 来动态加载路由匹配的组件 -->
      <el-main class="el-main" scroll="no">
        <router-view></router-view>
      </el-main>
    </el-container>
    </div>
  </template>
  
  <script>
  export default {
    mounted(){
      if(this.isTokenExpired()){
        this.$router.push("/login");
      }
      
    },
    data() {
    return {
      showModifyPassword: false
    };
  },
    computed: {
    username() {
      return localStorage.getItem('username');
    },
    breadcrumbList() {
      return this.$route.meta.breadcrumb || [];
    }
  },
    methods: {
      goToModifyPasswordPage() {
      // 这里假设要跳转到名为'modify-password'的路由页面
      // 如果实际项目中没有使用路由，请根据具体跳转方式修改此代码
      this.$router.push('/UpdatePassword');
    },
      exit(){
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        this.$router.push('/login');
      },
      isTokenExpired() {
      let token=localStorage.getItem('token')
      try {
        // 分割JWT，获取payload部分（第二部分）
        const payloadBase64 = token.split('.')[1];
      
        // 使用atob解码base64编码的payload
        const payloadJson = atob(payloadBase64);
      
       // 将解码后的JSON字符串转换为对象
        const payload = JSON.parse(payloadJson);
      
      // 检查exp字段是否存在
      if (!payload.exp) {
          console.warn('Token does not have an expiration time (exp field).');
          return true; // 没有exp字段的情况，认为token已过期
      }
      
      // 获取当前时间戳（秒为单位）
      const currentTime = Math.floor(Date.now() / 1000);
      
      // 比较当前时间和token的过期时间
      return payload.exp < currentTime;
  } catch (error) {
      console.error('Failed to decode or parse the token:', error);
      return true; // 如果解码或解析失败，认为token无效或已过期
  }
},
      handleSelect(index) {
      if(this.isTokenExpired()){
        this.$router.push("/login"); //
      }
        switch (index) {
          case "1":
            this.$router.push("/"); // 跳转到首页路径
            break;
          case "3":
            this.$router.push("/Applyinfo"); // 跳转到报名记录
            break;
          case "2":
            this.$router.push("/ApplyPage"); // 跳转到我要报名
            break;
          case "4":
            this.$router.push("/messages"); // 跳转到我的消息
            break;
          case "5":
            this.$router.push("/paymentrecords"); // 跳转到缴费记录
            break;
          case "6":
            this.$router.push("/MainGongao"); // 跳转到系统公告
            break;
          case "8":
          this.$router.push("/MyBnote"); // 跳转到系统公告
          break;
            
        }
      },
    },
  };
  </script>
  <style>
.green{
  position: relative;
  top:0;
  background-color:#1CC470;
  height: 3rem;
}
.username{
  position:absolute;
  right: 4rem;
  color: white;
  top:15px;
  font-weight: bold;
}
.modify{
  position:absolute;
  right: 4rem;
  color: white;
  top:15px;
  font-weight: bold;
  cursor:pointer;
  font-size: 17px;
}
.title{
  font-weight: bold;
  color:white;
  position:absolute;
  left:30px;
  top:15px;
}
.exit{
  position:absolute;
  right: 1rem;
  color: white;
  top:15px;
  font-weight: bold;
  cursor:pointer;
}
.el-aside{
  position:absolute;
  margin-left:30px;
  margin-top:3rem;
  height: 100%;
  background-image: url('../assets/login_bgx.gif');
}
.custom-breadcrumb {
    display: flex;
    width: 35rem;
    margin-top: 10px;
    margin-left:2px;
}
.el-breadcrumb__item {
    align-items: center;
    display: inline;
    float: left;
}
.el-bread{
  display: inline-block;
}
.br{
  display: inline-block;width: 15rem;
}
.member{
  margin-left:30px;
}
.el-main{
  padding:3% 0 0 0; 
  border-radius:30px;
  margin-top:3rem;
  background-image: url('../assets/login_bgx.gif');
  margin-left:2%;
  margin-right: 3%;
  overflow: scroll;
}
.el-main::-webkit-scrollbar{
      display: none;
}
.modify-password-link {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  color:black;
  width: 100px;
}
.el-menu-vertical-demo{
  border-right: 0px !important;
  background-image: url('../assets/login_bgx.gif');
}
</style>
  