<template>
    <el-form class="elb" :model="formData" ref="phoneForm"> <!-- 添加model属性并设置为formData -->
       <!-- 用户名输入框 -->
       <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请正确输入用户名，必须是数字和字母', trigger: 'blur',pattern:/[A-Z|a-z|0-9]+$/},{ validator: validateUsername, trigger: 'blur'}]">
            <el-input v-model="formData.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
      <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="formData.password" placeholder="请输入密码,不修改则不填"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="repassword" :rules="[{validator:validatePassword, message: '密码不一致', trigger: 'blur' }]">
            <el-input type="password" v-model="formData.repassword" placeholder="请输入密码"></el-input>
          </el-form-item>
      <el-form-item>
        <input type="hidden" @keyup.enter="login"/>
        <el-button type="primary" class="full-width submit-button1" @click="update">修改密码</el-button>
      </el-form-item>
    </el-form>
  </template>
  
  <script>
  import axios from 'axios';
  import { baseURL } from '@/api/base.js';
  
  export default {
    beforeCreate(){
      axios.post(baseURL + '/api/zk/verifyidcard', {idcard:localStorage.getItem('idcard')}, {
             headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
             }
           })
           .then((response) => {
            console.log(response.data.code)
             if(response.data.code!=200){
               this.$router.push('/UpdatePassword')
             }
           })
           .catch((error) => {
             console.error('提交错误:', error);
           }); 
    },
    mounted(){
      this.getusername()
    },
    data() {
      return {
        formData: {
          tel: '',
          vcode: '',
          password:'',
          repassword:'',
          username:''
        },
        showvCode: false,
        isButtonDisabled: false,
        countdown:60,
        // 完善手机号验证规则
        phoneRules: [
          {
            pattern: /^[1][3-9][0-9]{9}$/,
            message: '请输入正确的11位手机号',
            trigger: 'blur'
          }
        ],
        buttonLabel:'获取验证码'
      };
    },
    computed: {
      isPhoneValid() {
        const phoneRegex = /^[1][3-9][0-9]{9}$/;
        return phoneRegex.test(this.formData.tel);
      }
    },
    methods: {
      getusername(){
        axios.post(baseURL + '/api/zk/getusername', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then((response) => {
              if(response.data.code==200){
                this.formData.username=response.data.data
              }else{
                this.$message.error(response.data.msg);
              }

            })
            .catch((error) => {
              this.$message.error('密码修改失败');
              console.error('提交错误:', error);
            }); 
      },
      validatePassword(rule, value, callback) {
   if (this.formData.repassword!== this.formData.password) {
      callback(new Error('密码不一致'));
    }else{
      callback();
    }
  },
      update() {
        if(this.formData.password&&this.formData.password.length<6){
          this.$message.error('密码必须大于6位');
          return;
        }
          axios.post(baseURL + '/api/zk/updatepassword',{password:this.formData.password,username:this.formData.username}, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then((response) => {
              if (response.data.code == 200) {
                this.$message.success(response.data.msg);
                this.$router.push('/');
              } else {
                this.$message.error(response.data.msg);
              }
            })
          .catch((error) => {
              this.$message.error('密码修改失败');
              console.error('提交错误:', error);
            })
      },
      updatetel() {
         this.$router.push('/UpdateTel')
      }
    }
  };
  </script>
  
  <style>
  .full-width {
    width: 100%;
  }
  .elb{
    margin-top: 30px;
  }
  </style>