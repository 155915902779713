<template>
  <Layout>
    <h1 align="center">请选择支付方式</h1>
    <div style="display: flex; justify-content: center; align-items: center; height: 10vh;">
      <div style="text-align: center;">
        <el-radio-group v-model="paymentMethod">
          <el-radio label="alipay">
            <div class="icon alipay-icon"></div>支付宝
          </el-radio>
          <el-radio label="wechat">
            <div class="icon wechat-icon"></div>微信支付
          </el-radio>
        </el-radio-group>
        <div style="margin-top: 20px;">
          <el-button @click="submitForm">提交</el-button>
        </div>
      </div>
    </div>
    <div v-if="qrCodeUrl" style="margin-top: 20px;text-align: center;">
      <img :src="qrCodeUrl" alt="微信支付二维码" />
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/api/base.js';
export default {
  props:['id'],
  data() {
    return {
      paymentMethod: 'alipay',
      qrCodeUrl: '' // 存放二维码URL
    };
  },
  methods: {
    submitForm() {
      if (this.paymentMethod === 'alipay') {
        this.initiatealiPayment()
      } else if (this.paymentMethod === 'wechat') {
          this.initiatePayment() 
      }
    },
    initiatealiPayment() {
      axios.post(baseURL+'/api/pay/alipay', { id:this.id },{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }}).then(response => {
        const { data } = response;
        if (data.success) {
          const decodedBody = decodeURIComponent(data.body);

          let dwSafari = window.open();
if (dwSafari) {
    dwSafari.document.open();
    dwSafari.document.write("<html><head><meta charset='utf-8'></head><body>" + decodedBody + "</body></html>");
    dwSafari.document.close(); // 关闭文档流
} else {
    console.error("窗口未成功打开");
}

          // 开始轮询支付状态
          this.checkPaymentStatus(this.id);
        } else {
           if(data.code==500){
              this.$router.push('/')
           }
            this.$message.error(data.msg);
        }
      });
    },
    initiatePayment() {
      axios.post(baseURL+'/api/pay/wechat', { id:this.id },{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }}).then(response => {
        const { data } = response;
        if (data.success) {
          // 展示二维码
          this.qrCodeUrl = data.code_url;
          // 开始轮询支付状态
          this.checkPaymentStatus(this.id);
        } else {
           if(data.code==500){
              this.$router.push('/')
           }
            this.$message.error(data.msg);
        }
      });
    },
    checkPaymentStatus(id) {
      let num=0
      const interval = setInterval(() => {
        axios.post(baseURL+'/api/pay/checkStatus', { id: id },{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }}).then(res => {
          if (res.data.success) {
            clearInterval(interval); // 停止轮询
            this.$message.success('支付成功');
            this.$router.push('/Applyinfo')
          }else{
            num++
            if(num==10){
              this.$message.error('支付失败');
              clearInterval(interval); // 停止轮询
              this.$router.push('/Applyinfo')
            }
          }
        });
      }, 5000); // 每5秒查询一次
    }
  }
};
</script>

<style>
.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.alipay-icon {
  background-image: url('../images/alipay.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.wechat-icon {
  background-image: url('../images/weipay.png');
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
