<template>

     <Layout>
        <el-table :data="tablelist" style="width: 100%">
    <el-table-column prop="out_trade_no" label="订单号" width="180"></el-table-column>
    <el-table-column prop="name" label="姓名" width="180"></el-table-column>
    <el-table-column prop="tel" label="电话"></el-table-column>
    <el-table-column prop="is_pay" label="是否支付"></el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper" style="margin-left:30%;">
    </el-pagination>
    </Layout>
    </template>
    <script>
    import axios from 'axios';
    import {baseURL } from '@/api/base.js';
    export default {
      data() {
        return {
            tablelist: [
                // 动态字段列表
            ],
            formlist: {},
            currentPage: 1,
            pageSize: 10,
            total: 0
        }
      },
      mounted(){
         this.fetchDetail()
      },
      methods: {
        handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchDetail();
    },
    // 当当前页面改变时触发
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchDetail();
    },
        fetchDetail() {
        var that=this;
            axios
            .post(baseURL+'/api/report/getpay', {currentPage:this.currentPage,pageSize:this.pageSize},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.code==503){
                window.location.reload()
              }
              if(response.data.data){
                that.tablelist= response.data.data;
                that.total=response.data.total;
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            });  
          }
      },
    };
    </script>
    <style>
    </style>