<template>
    <div id="app">
        <!-- 如果是移动设备，显示模态框 -->
    <el-modal v-if="isMobileValid" :visible="modalVisible" title="提示" @close="modalVisible = false">
      <h3>请在电脑上打开此页面。</h3>
    </el-modal>
    <div v-else>
      <router-view></router-view>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    computed: {
      isMobileValid() {
        const userAgent = navigator.userAgent;
        const mobilePattern = /(phone|pad|pod|iPhone|iPod|iPad|Android|BlackBerry|IEmobile|Opera Mini)/i;
        const isMobile = mobilePattern.test(userAgent);
        return isMobile
      }
    }
  };
  </script>
  <script setup>
 const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}
  
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}
</script>

  
  <style>
  /* 添加一些全局样式，或者保持空白 */
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  el-container{
    padding: 0 0 0 0;
  }
  .scroll{
            overflow: scroll;
}
        .scroll::-webkit-scrollbar{
            display: none;
        }
        h2{
          padding: 10px;
        }
</style>
  