<template>
     <div style="padding-left: 3%;">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane name="0" label="全部" class="mytabs">
       <el-table :data="tableData" style="width: 100%">
         <el-table-column prop="subject" label="标题"></el-table-column>
         <el-table-column prop="message" label="内容"></el-table-column>
         <el-table-column prop="is_read" label="是否已读">
            <template v-slot="scope">
        <span v-if="scope.row.is_read === 1">已读</span>
        <span v-else>未读</span>
      </template>
    </el-table-column>
       </el-table>
        </el-tab-pane>
        <el-tab-pane name="1" label="未读" class="mytabs">
       <el-table :data="tableData" style="width: 100%">
         <el-table-column prop="subject" label="标题"></el-table-column>
         <el-table-column prop="message" label="内容"></el-table-column>
         <el-table-column prop="is_read" label="是否已读">
            <template v-slot="scope">
        <span v-if="scope.row.is_read === 1">已读</span>
        <span v-else>未读</span>
      </template>
    </el-table-column>
       </el-table>
        </el-tab-pane>
        <el-tab-pane name="2" label="已读">
          <el-table :data="tableData" style="width: 100%">
         <el-table-column prop="subject" label="标题"></el-table-column>
         <el-table-column prop="message" label="内容"></el-table-column>
         <el-table-column prop="is_read" label="是否已读">
            <template v-slot="scope">
        <span v-if="scope.row.is_read === 1">已读</span>
        <span v-else>未读</span>
      </template>
    </el-table-column>
       </el-table>
          </el-tab-pane>
      </el-tabs>
     </div>
     <el-pagination style="margin-left:30%;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
     <el-button type="primary" @click="markAllAsRead" style="float:left;">全部设为已读</el-button>
    </template>
    <script>
    import axios from 'axios';
    import { baseURL } from '@/api/base.js';
      export default {
     data() {
       return {
         tableData: [],
         currentPage: 1,
         pageSize: 10,
         total: 0,
         activeTab:0
       };
     },
     mounted()
     {
      this.activeTab = '0';
    this.currentPage = 1;
    this.$nextTick(()=>{
      this.gettableData();    
    })
     },
     methods: {
        convertStatus(status) {
      switch (status) {
        case 0:
          return '状态0';
        // 其他状态...
        default:
          return '未知状态';
      }
    },
    async handleTabClick(tab) {
    this.activeTab = tab.name;
    this.currentPage = 1;
    await this.$nextTick();
    this.gettableData(this.activeTab);
},
        handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.gettableData();
    },
    // 当当前页面改变时触发
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.gettableData();
    },
       gettableData(tab=0)
       {
        var that=this;
        axios
            .post(baseURL+'/api/message/getmessage', {pageSize:this.pageSize,currentPage:this.currentPage,tab:tab},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.data){
                that.tableData= response.data.data;
                that.total=response.data.total;
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
       },
       editItem(item) {
         // 处理编辑操作的逻辑
         console.log('编辑：', item);
       },
       deleteItem(item) {
         // 处理删除操作的逻辑
         console.log('删除：', item);
       },
        markAllAsRead() {
            axios
            .post(baseURL+'/api/message/setmessageread', {},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
                console.log(response)
                this.$message.success('设置成功')
                window.location.reload()
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
        }
     }
   };
    </script>
    <style>
    </style>