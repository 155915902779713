import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/LoginPage.vue';
import RegisterPage from '../components/RegisterPage.vue';
import MainLayout from '../components/MainLayout.vue';
import ApplyPage from '../components/ApplyPage.vue';
import MessagesPage from '../components/MessagesPage.vue';
import PaymentRecords from '../components/PaymentRecords.vue';
import MainGongao from '../components/MainGongao.vue';
import ApplyInfo from '../components/ApplyInfo.vue';
import UpdatePassword from '../components/UpdatePassword.vue';
import ApplyDetail from '../components/ApplyDetail.vue';
import ListPage from '../components/ListPage.vue';
import MyBnote from '../components/MyBnote.vue';
import setReport from '../components/setReport.vue';
import payPage from '../components/payPage.vue';
import UpdateTel from '../components/UpdateTel.vue';
import verifyTel from '../components/verifyTel.vue';
import UpdateIdCard from '../components/UpdateIdCard.vue';
const routes = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/register',
    component: RegisterPage,
  },{
      path: '/',
      component: MainLayout, // 使用 Layout 组件作为父布局
      children: [
        {
          path: '/',
          component:ListPage ,
          meta: { requiresAuth: true,
            breadcrumb: [
              { text: '首页', to: '/' }
            ]
          }
        },
        {
          path: '/messages',
          component: MessagesPage,
          meta: { 
            breadcrumb: [
              { text: '首页', to: '/' },
              { text: '我的消息', to: '/messages' }
            ]
          }
        },
        {
          path: '/paymentrecords',
          component: PaymentRecords,
          meta:{
            breadcrumb: [
            { text: '首页', to: '/' },
            { text: '支付记录', to: '/messages' }
            ]
          }
        },
        {
          path: '/ApplyPage',
          component: ApplyPage,
          meta: { 
            breadcrumb: [
              { text: '首页', to: '/' },
              { text: '我要报名', to: '/ApplyPage' }
            ]
          }
        },
        {
          path: '/ApplyInfo',
          component: ApplyInfo,
          meta: { 
            breadcrumb: [
              { text: '首页', to: '/' },
              { text: '报名记录', to: '/Applyinfo' }
            ]
          }
        },
        {
          path: '/Maingongao',
          component: MainGongao,
          meta: { 
            breadcrumb: [
              { text: '首页', to: '/' },
              { text: '系统公告', to: 'MainGongao' }
            ]
          }
        },
        {
          path: '/UpdatePassword/:id',
          component: UpdatePassword,
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '修改信息', to: '/MyBnote' },
              { text: '验证身份证', to: '/UpdatePassword' }
            ]
          }
        },{
          path: '/applyDetail/:id',
          name: 'ApplyDetail',
          component: ApplyDetail,
          props: true, // 传递路由参数为组件的 props
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '报名信息', to: 'applyinfo' },
              { text: '报名详情', to: '/applyDetail/' }
            ]
          }
        },
        {
          path: '/MyBnote',
          name: 'MyBnote',
          component: MyBnote,
          props: true, // 传递路由参数为组件的 props
          meta: { 
            breadcrumb: [
              { text: '首页', to: '/' },
              { text: '修改信息', to: '/MyBnote' }
            ]
          }
        },
        {
          path: '/setReport/:id',
          name: 'setReport',
          component: setReport,
          props: true, // 传递路由参数为组件的 props
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '报名信息', to: '/applyinfo' },
              {
                text: '报名详情',
                // 使用函数来根据当前路由的id参数动态生成to路径
                to: (route) =>{
                  if (route.params.id) {
                    return `/applyDetail/${route.params.id}`;
                } else {
                    // 如果当前路由对象中没有获取到id参数，这里可以做一些默认处理，比如返回首页路径等
                    return '/';
                }
                }
            },
              { text: '正在报名', to: '/setreport/:id' }
            ]
          }
        },
        {
          path: '/payPage/:id',
          name: 'payPage',
          component:payPage,
          props: true, // 传递路由参数为组件的 props
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '报名信息', to: 'applyinfo' },
              { text: '支付页面', to: '/applyDetail/' },
            ]
          }
        },
        {
          path: '/UpdateTel',
          name: 'UpdateTel',
          component:UpdateTel,
          props: true, // 传递路由参数为组件的 props
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '修改信息', to: '/applyinfo' },
              { text: '修改手机号', to: '/UpdateTel' },
            ]
          }
        },
        {
          path: '/verifyTel',
          name: 'verifyTel',
          component:verifyTel,
          props: true, // 传递路由参数为组件的 props
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '修改信息', to: 'applyinfo' },
            ]
          }
        },
        {
          path: '/UpdateIdCard',
          name: 'UpdateIdCard',
          component:UpdateIdCard,
          props:true,
          meta:{
            breadcrumb:[
              { text: '首页', to: '/' },
              { text: '修改身份证信息', to: 'applyinfo' },
            ]
          }
        }
      ]
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 路由守卫，检查 token 是否存在或过期
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.meta.requiresAuth && isTokenExpired(token)) {//|| isTokenExpired(token)
    console.log(to)
    console.log(from)
    console.log(from.path=='/register')
    if(from.path=='/register'){
       next('/register');
    }else{
      next('/login');
    }
  } else {
    next();
  }
});
// 简单判断 token 是否过期
function isTokenExpired(token) {
  try {
      // 分割JWT，获取payload部分（第二部分）
      const payloadBase64 = token.split('.')[1];
      
      // 使用atob解码base64编码的payload
      const payloadJson = atob(payloadBase64);
      
      // 将解码后的JSON字符串转换为对象
      const payload = JSON.parse(payloadJson);
      
      // 检查exp字段是否存在
      if (!payload.exp) {
          console.warn('Token does not have an expiration time (exp field).');
          return true; // 没有exp字段的情况，认为token已过期
      }
      
      // 获取当前时间戳（秒为单位）
      const currentTime = Math.floor(Date.now() / 1000);
      
      // 比较当前时间和token的过期时间
      return payload.exp < currentTime;
  } catch (error) {
      console.error('Failed to decode or parse the token:', error);
      return true; // 如果解码或解析失败，认为token无效或已过期
  }
}

export default router;
