<template>
  <div style="padding-left: 3%;">
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane name="0" label="全部">
        <el-table :data="originalData" style="width: 100%">
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="content" label="内容">
            <template v-slot="scope">
              <div v-if="scope && scope.row" class="contentClass">{{cleanContent(scope.row.content)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="is_read" label="是否已读">
            <template v-slot="scope">
              <div v-if="scope && scope.row" class="contentClass">{{scope.row.is_read==0?'未读':'已读'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button v-if="scope && scope.row" type="text" @click="showDialog(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="1" label="未读">
        <el-table :data="originalData" style="width: 100%">
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="content" label="内容">
            <template v-slot="scope">
              <div v-if="scope && scope.row" class="contentClass">{{ cleanContent(scope.row.content) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button v-if="scope && scope.row" type="text" @click="showDialog(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="2" label="已读">
        <el-table :data="originalData" style="width: 100%">
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="content" label="内容">
            <template v-slot="scope">
              <div v-if="scope && scope.row" class="contentClass">{{ cleanContent(scope.row.content) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button v-if="scope && scope.row" type="text" @click="showDialog(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog v-model="dialogVisible" title="详情" width="30%">
      <div v-html="dialogContent"></div>
      <template v-slot:footer>
        <el-button @click="getread(dialogId)">取消</el-button>
        <el-button type="primary" @click="getread(dialogId)">确定</el-button>
      </template>
    </el-dialog>

    <el-pagination
      style="margin-top: 20px; display: flex; justify-content: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 10, 20, 50]"
      :total="originalData.length"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>

    <el-button type="primary" @click="markAllAsRead" style="margin-top: 20px;">全部设为已读</el-button>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, nextTick } from 'vue';
import axios from 'axios';
import { baseURL } from '@/api/base.js';
import router from '@/router';

let resizeObserverInstance;

export default {
  name: 'MainGongao',
  setup() {
    const dialogVisible = ref(false);
    const dialogContent = ref('');
    const rawData = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const activeTab = ref('0');
    const dataLoaded = ref(false);
    const dialogId = ref(0);
    const originalData = ref([]);

    const loadData = async () => {
      try {
        let tabToSend = activeTab.value === '1' ? 1 : 2;
        console.log(`当前 Tab: ${activeTab.value}, 请求 Tab 参数: ${tabToSend}`);
        const response = await axios.post(baseURL + '/api/notice/getall', {
          tab: tabToSend,
          pageSize: pageSize.value,
          currentPage: currentPage.value
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.data.code === 503) {
          window.lcation.reload()
        } else {
          if(response.data.data){
            console.log('API 响应数据:', response.data.data);
          rawData.value = response.data.data;
          dataLoaded.value = true;
          originalData.value = response.data.data;
          await nextTick();
          console.log('数据加载完成');
          }
        
        }
      } catch (error) {
        console.error('获取公告数据出错：', error);
      }
    };

    const getread = (id) => {
      axios.post(baseURL + '/api/notice/setread', {
        id: id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      dialogVisible.value = false;
      window.location.reload()
    };
    const showDialog = (row) => {
      dialogContent.value = row.content;
      dialogVisible.value = true;
      dialogId.value = row.id;
    };

    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };
    const handleTabClick = async (tab) => {
    activeTab.value = tab.name;  // 更新 activeTab 的值
    currentPage.value = 1;  // 切换标签时重置分页
    await nextTick();  // 等待 DOM 更新完成
    loadData();  // 重新加载数据
};


const markAllAsRead = () => {
    // 提取 originalData 中所有的 id
    const ids = originalData.value.map(item => item.id);

    axios.post(baseURL + '/api/notice/setallread', {
        ids: ids  // 将 id 列表传递给后端
    }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }).then((res) => {
        if(res.data.code==200){
          window.location.reload()
        }else{
          router.push('/')
        }
    }).catch((error) => {
        console.error('标记为已读失败：', error);
    });
};


    const cleanContent = (content) => content.replace(/<[^>]+>/g, '').length>10?content.replace(/<[^>]+>/g, '').substring(0,10)+'...':content.replace(/<[^>]+>/g, '');

    onMounted(loadData);

    return {
      dialogVisible,
      dialogContent,
      currentPage,
      pageSize,
      activeTab,
      showDialog,
      handleSizeChange,
      handleCurrentChange,
      handleTabClick,
      markAllAsRead,
      cleanContent,
      dataLoaded,
      getread,
      originalData,
      dialogId
    };
  },
};
</script>

<style>
.el-dialog {
  z-index: 9999;
}
</style>
